import React, { useContext, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { CardMedia, Typography } from '@material-ui/core';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';

import SplashImage from 'Images/splash.png';
import LogoImage from 'Images/copima-logo.svg';
import UserContext from '../../Shared/UserContext';
import { loginUser } from './LoginService';
import { resetLogin } from '../../Shared/UserContext/Actions';
import useStyles from './style';

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    dispatch,
    state: { status, errorMessage },
  } = useContext(UserContext);
  useEffect(() => {
    if (status === 'error') {
      dispatch(resetLogin());
      toast.error(errorMessage);
    }
  }, [status, errorMessage, dispatch]);

  const [values, setValues] = React.useState({
    username: '',
    password: '',
  });
  const login = async () => {
    await loginUser(dispatch, values, history);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onSubmit = (e) => {
    e.preventDefault();
    login();
  };
  return (
    <>
      {status === 'loading' && <GlobalLoader size="100vh" />}
      <Box
        style={{
          height: '100vh',
          display: 'flex',
          boxSizing: 'border-box',
          background: '#F9FAFC 0% 0% no-repeat padding-box',
          opacity: 1,
        }}
      >
        <Box style={{ padding: 16, position: 'relative' }}>
          <CardMedia
            image={SplashImage}
            style={{ width: 'auto', height: '100%' }}
            component="img"
          />
          <CardMedia
            image={LogoImage}
            style={{
              position: 'absolute',
              top: 55,
              left: 54,
              height: 72,
              width: 'auto',
            }}
            component="img"
          />
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: '0 100px',
            flexGrow: 1,
          }}
        >
          <form noValidate autoComplete="off" onSubmit={onSubmit}>
            <Box
              marginBottom="90px"
              style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}
            >
              <Typography
                style={{
                  font: 'normal normal 600 32px/39px Montserrat',
                  letterSpacing: 0,
                  color: '#000000',
                  textTransform: 'capitalize',
                }}
              >
                Se Connecter
              </Typography>
              <Typography
                style={{
                  font: 'normal normal 500 18px/29px Montserrat',
                  letterSpacing: 0,
                  color: '#000000',
                  textTransform: 'lowercase',
                  marginTop: 21,
                }}
              >
                Pour accéder aux modules Copima Core
              </Typography>
            </Box>
            <Box marginTop="25px">
              <Typography
                style={{
                  font: 'normal normal 600 24px/29px Montserrat',
                  letterSpacing: 0,
                  color: '#000000',
                  opacity: 1,
                }}
              >
                Nom d&apos;utilisateur
              </Typography>
              <TextField
                label="Nom d'utilisateur"
                variant="outlined"
                autoFocus
                margin="normal"
                value={values.username}
                onChange={handleChange('username')}
                InputLabelProps={{
                  classes: { root: classes.labelField },
                }}
                InputProps={{
                  classes: { notchedOutline: classes.notchedField },
                }}
                style={{ width: '70%', background: '#F5F5F5 0% 0% no-repeat padding-box' }}
              />
              <Typography
                style={{
                  font: 'normal normal 600 24px/29px Montserrat',
                  letterSpacing: 0,
                  color: '#000000',
                  opacity: 1,
                  marginTop: 20,
                }}
              >
                Mot de passe
              </Typography>
              <FormControl
                margin="normal"
                variant="outlined"
                style={{ width: '70%', background: '#F5F5F5 0% 0% no-repeat padding-box' }}
              >
                <InputLabel htmlFor="outlined-adornment-password" className={classes.labelField}>
                  Mot de passe
                </InputLabel>
                <OutlinedInput
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="medium"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  labelWidth={70}
                  classes={{ notchedOutline: classes.notchedField }}
                />
              </FormControl>
            </Box>
            <Box marginTop="45px">
              <Button
                disabled={!values.password || !values.username}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                style={{
                  background:
                    'transparent linear-gradient(261deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
                  boxShadow: '0px 4px 10px #108CD650',
                  borderRadius: 12,
                  height: 64,
                  font: 'normal normal 600 18px/22px Montserrat',
                  letterSpacing: 0,
                  color: '#FFFFFF',
                  width: '70%',
                }}
              >
                Se connecter
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

Login.propTypes = {};

export default Login;
