import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    top: 20,
    width: 1,
    padding: 0,
  },
  position: 'absolute',
  avatar: {
    color: '#FFFFFF',
    backgroundColor: '#88BCDE',
    width: 32,
    height: 32,
    fontSize: 14,
  },
  addIcon: {
    color: 'transparent',
    padding: 0,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 11,
    color: 'rgba(0, 0, 0, 0.5)',
  },
  data: {
    fontSize: 12,
    color: '#000000',
    fontFamily: 'SFProDisplay !important',
  },
  pagination: {
    color: 'rgba(0, 0, 0, 0.5)',
    fontFamily: 'SFProDisplay !important',
  },
  row: {
    height: 48,
  },
  chip: {
    height: 20,
  },
}));

export default function EnhancedTable({
  getData,
  rows,
  headCells,
  count = 0,
  pagination = true,
  update,
  containerClass,
  resetSort,
  rowsPerPageOptions,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    rowsPerPageOptions ? rowsPerPageOptions[0] : 5,
  );
  useEffect(() => {
    setOrder('asc');
    setOrderBy('');
    setPage(0);
    setRowsPerPage(rowsPerPageOptions ? rowsPerPageOptions[0] : 5);
  }, [resetSort]);
  useEffect(() => {
    const orderList = orderBy.split('+');
    if (orderList.length === 1 && getData) {
      getData({
        page,
        size: rowsPerPage,
        sort: orderBy ? `${orderBy},${order}` : '',
      });
    } else if (getData) {
      getData({
        page,
        size: rowsPerPage,
        sort: `${orderList[0]},${order}&sort=${orderList[1]},${order}`,
      });
    }
  }, [page, rowsPerPage, order, orderBy, getData, update]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);
  return (
    <div className={classes.root}>
      {false && <EnhancedTableToolbar />}
      <TableContainer className={containerClass || ''}>
        <Table className={classes.table} size="medium" stickyHeader>
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
          />
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                className={classes.row}
                onClick={row.click}
              >
                {headCells.map((headCell) => {
                  const value = row[headCell.id];
                  return (
                    <TableCell size="medium" key={headCell.id} align={headCell.align}>
                      {headCell.render({ value, row })}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={headCells.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          labelRowsPerPage="Lignes par page"
          rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          classes={{ caption: classes.pagination, select: classes.pagination }}
        />
      )}
    </div>
  );
}
