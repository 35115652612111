/* eslint-disable import/prefer-default-export */
import SalesModule from 'Images/sales_module.svg';
import OpsModule from 'Images/ops_module.svg';
import ArchiscanModule from 'Images/archiscan_module.svg';

export const IMAGES = [
  {
    id: 'SALES',
    image: SalesModule,
  },
  {
    id: 'OPS',
    image: OpsModule,
  },
  {
    id: 'ARCHISCAN',
    image: ArchiscanModule,
  },
  {
    id: 'EDGE',
    image: SalesModule,
  },
];
