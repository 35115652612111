import { Box, CardMedia, Typography } from '@material-ui/core';
import React from 'react';
import { IMAGES } from '../constants';

const Module = ({ module }) => {
  const openUrlNewTab = () => {
    window.open(module.url, '_blank');
  };
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: 232,
        height: 233,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 12px 26px #0000000D',
        borderRadius: 12,
        opacity: 1,
        padding: '12px 6px',
        cursor: 'pointer',
      }}
      onClick={openUrlNewTab}
    >
      <Box style={{
        flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}
      >
        <CardMedia src={IMAGES.find((i) => i.id === module.id)?.image} component="img" style={{ height: 114, width: 'auto' }} />
      </Box>
      <Typography
        style={{
          font: 'normal normal 500 18px/22px Montserrat',
          letterSpacing: 0,
          color: '#000000',
          textTransform: 'uppercase',
          textAlign: 'center',
        }}
      >
        {module.label}
      </Typography>
    </Box>
  );
};

export default Module;
