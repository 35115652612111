export const fields = [
  {
    id: 'firstName',
    label: 'FirstName',
    type: 'text',
    defaultValue: '',
  },
  {
    id: 'lastName',
    label: 'lastName',
    type: 'text',
    defaultValue: '',
  },
  {
    id: 'email',
    label: 'Email',
    type: 'text',
    defaultValue: '',
  },
  {
    id: 'username',
    label: 'Username',
    type: 'text',
    defaultValue: '',
  },
  {
    id: 'password',
    label: 'password',
    type: 'password',
    defaultValue: '',
  },
];

export const DEFAULT_VALUES = fields.reduce(
  (prev, curr) => ({ ...prev, [curr.id]: curr.defaultValue }),
  { modules: [] },
);
