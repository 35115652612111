import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    top: 20,
    width: 1,
    padding: 0,
  },
  position: 'absolute',
  avatar: {
    color: '#FFFFFF',
    backgroundColor: '#88BCDE',
    width: 32,
    height: 32,
    fontSize: 14,
  },
  addIcon: {
    color: 'transparent',
    padding: 0,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 11,
    color: 'rgba(0, 0, 0, 0.5)',
  },
  data: {
    fontSize: 12,
    color: '#000000',
    fontFamily: 'SFProDisplay !important',
  },
  pagination: {
    color: 'rgba(0, 0, 0, 0.5)',
    fontFamily: 'SFProDisplay !important',
  },
  row: {
    height: 48,
    width: '100%',
  },
  chip: {
    height: 20,
  },
}));
const specialCells = ['avatar', 'status', 'affect', 'count-down'];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator, level, skip) {
  if (skip) return array;
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = level ? comparator(a[0][0], b[0][0]) : comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EnhancedTableNested({
  rows, headCells, count = 0,
  pagination = true, containerClass, defaultRowsPerPage = 5, values, setValues,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderSecond, setOrderSecond] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(headCells[0]?.id);
  const [orderBySecond, setOrderBySecond] = React.useState(headCells[0]?.id);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
  const [collapse, setCollapse] = React.useState({});

  const handleRequestSort = (event, property) => {
    if (property === 'reference') {
      const isAsc = orderBySecond === property && orderSecond === 'asc';
      setOrderSecond(isAsc ? 'desc' : 'asc');
      setOrderBySecond(property);
    } else {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (prop) => (event) => {
    // eslint-disable-next-line no-nested-ternary
    setValues({ ...values, [prop]: event.target.type === 'number' ? (event.target.value < 0 ? 0 : event.target.value) : event.target.value });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    setOrder('asc');
    setOrderBy(headCells[0].id);
    setPage(0);
    setRowsPerPage(defaultRowsPerPage);
  }, [rows]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);
  return (
    <div className={classes.root}>
      {false && <EnhancedTableToolbar />}
      <TableContainer className={containerClass || ''}>
        <Table
          className={classes.table}
          size="medium"
          stickyHeader
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
          />
          <TableBody>
            {
                            stableSort(rows, getComparator(order, orderBy), true, orderBy === 'reference')
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, index) => (
                                <>
                                  {collapse[index] && (
                                    <TableRow
                                      hover
                                      tabIndex={-1}
                                      // eslint-disable-next-line react/no-array-index-key
                                      key={index}
                                      className={classes.row}
                                    >
                                      {headCells.map((headCell, ind) => (
                                        <TableCell
                                          align="left"
                                          style={headCell.parentStyle}
                                          // eslint-disable-next-line react/no-array-index-key
                                          key={`${headCell.id}-${index}`}
                                          className={clsx(
                                            classes.data,
                                            !specialCells.includes(headCell.type)
                                            && headCell.related && !row[headCell.related]
                                              ? row[headCell.parentClass] : '',
                                          )}
                                          onClick={ind === 0
                                            ? () => setCollapse({ [index]: false })
                                            : null}
                                        >
                                          {ind !== 0 && '-'}
                                          {ind === 0 && (
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <Link
                                              component="button"
                                              variant="body2"
                                            >
                                              <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                                color: '#2188CC',
                                              }}
                                              >
                                                <ArrowDropDownIcon />
                                                Niveau
                                                {' '}
                                                {row[0].level}
                                              </div>
                                            </Link>
                                          )}
                                        </TableCell>

                                      ))}
                                    </TableRow>
                                  )}
                                  {!collapse[index] && (
                                    stableSort(row, getComparator(orderSecond, orderBySecond))
                                      .map((rowItem, indexRow) => (
                                        <TableRow
                                          hover
                                          tabIndex={-1}
                                          key={rowItem.id}
                                          className={classes.row}
                                        >
                                          {headCells.map((headCell, indRowItem) => (
                                            <TableCell
                                              align="left"
                                              style={headCell.parentStyle}
                                            // eslint-disable-next-line react/no-array-index-key
                                              key={`${rowItem.id}-${headCell.id}`}
                                              className={clsx(
                                                classes.data,
                                                !specialCells.includes(headCell.type)
                                              && headCell.related && !row[headCell.related]
                                                  ? row[headCell.parentClass] : '',
                                              )}
                                              onClick={indRowItem === 0
                                                ? () => setCollapse({ [index]: true })
                                                : null}
                                            >
                                              {indRowItem !== 0
                                            && (
                                            <headCell.Render
                                              value={values[`${headCell.id}::${rowItem.id}`]}
                                              onHandleChange={handleChange(`${headCell.id}::${rowItem.id}`)}
                                              label={rowItem[headCell.id]}
                                              classes={rowItem[headCell.class]}
                                              preview={rowItem.preview}
                                              orderParams={
                                                { order: orderSecond, orderBy: orderBySecond }
                                              }
                                              onHandleOrders={
                                                (e) => handleRequestSort(e, orderBySecond)
                                              }
                                              first={indexRow === 0}
                                            />
                                            )}
                                              {indRowItem === 0 && (
                                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                              <Link
                                                component="button"
                                                variant="body2"
                                              >
                                                <div style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  flexWrap: 'wrap',
                                                  color: '#2188CC',
                                                }}
                                                >
                                                  {indexRow === 0 && (
                                                  <>
                                                    <ArrowDropUpIcon />
                                                    Niveau
                                                    {' '}
                                                    {rowItem.level}
                                                  </>
                                                  )}
                                                </div>
                                              </Link>
                                              )}
                                            </TableCell>

                                          ))}
                                        </TableRow>
                                      ))
                                  )}
                                </>
                              ))
            }
            {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
      <TablePagination
        labelRowsPerPage="Lignes par page"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        classes={{ caption: classes.pagination, select: classes.pagination }}
      />
      )}
    </div>
  );
}
