import { Box } from '@material-ui/core';
import React from 'react';
import SideBar from 'Shared/SideBar';

const Layout = ({ children }) => (
  <Box style={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
    <SideBar />
    <Box flexGrow={1}>{children}</Box>
  </Box>
);

export default Layout;
