import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Alert from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import Header from 'Shared/Header';
import { ChangeMyPassword, defaultMessage } from '../UsersService';
import { fields, DEFAULT_VALUES } from './constants';

const ChangePassword = () => {
  const [values, setValues] = useState(DEFAULT_VALUES);
  const [loading, setLoading] = useState(false);
  const handleChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleUpdatePassword = async () => {
    try {
      setLoading(true);
      await ChangeMyPassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      });
      toast.success('Votre mot de passe a été bien mis à jour');
      setValues(DEFAULT_VALUES);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  };
  const isValid = Object.keys(DEFAULT_VALUES).reduce((prev, curr) => prev && values[curr], true)
    && values.confirmPassword === values.newPassword;
  return (
    <Box>
      {loading && <GlobalLoader size="100vh" />}
      <Header />
      <Box style={{ overflowY: 'auto', height: 'calc(100vh - 64px)' }}>
        <Box style={{ margin: '20px 50px' }}>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            Changer votre mot de passe
          </Typography>
          <Grid container alignItems="center" direction="column" spacing={2}>
            {fields.map((field) => (
              <Grid item>
                <TextField
                  label={field.label}
                  variant="outlined"
                  value={values[field.id]}
                  onChange={handleChange}
                  name={field.id}
                  style={{ width: 400 }}
                  type={values.showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ))}
            <Grid item>
              {values.confirmPassword !== values.newPassword && (
                <Alert severity="error">les deux mots de passe ne correspondent pas</Alert>
              )}
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="large"
                style={{ width: 400 }}
                disabled={!isValid}
                onClick={handleUpdatePassword}
              >
                Enregistrer
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
