import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  SubMenu,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import './style.css';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { CardMedia, withStyles } from '@material-ui/core';
import PostAddIcon from '@material-ui/icons/PostAdd';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { Link, useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import ViewListIcon from '@material-ui/icons/ViewList';
import StorefrontIcon from '@material-ui/icons/Storefront';
import UserContext from 'Shared/UserContext';
import { resetLogin } from 'Shared/UserContext/Actions';
import CopimaLogo from 'Images/copima_logo_sm.svg';
import { ReactComponent as LogoutIcon } from './icons/logout.svg';

const styles = {
  sectionTitle: {
    position: 'static',
    width: 131,
    height: 16,
    left: 12,
    top: 16,
    fontFamily: 'Noto Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 10,
    lineHeight: '158%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.15em',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    margin: '0px 12px',
  },
  avatarBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: 40,
    marginBottom: 12,
    marginTop: 56,
    padding: '8px 35px 8px 20px',
  },
  username: {
    margin: 0,
    fontWeight: 400,
    fontSize: 18,
    color: '#FFFFFF',
  },
  userRole: {
    margin: 0,
    fontSize: 12,
    fontWeight: 400,
    color: '#FFFFFF',
  },
  borderCorner: {
    background: `linear-gradient(to right, #2a69bc 2px, transparent 2px) 0 0,
        linear-gradient(to right, #2a69bc 2px, transparent 2px) 0 100%,
        linear-gradient(to left, #2a69bc 2px, transparent 2px) 100% 0,
        linear-gradient(to left, #2a69bc 2px, transparent 2px) 100% 100%,
        linear-gradient(to bottom, #2a69bc 2px, transparent 2px) 0 0,
        linear-gradient(to bottom, #2a69bc 2px, transparent 2px) 100% 0,
        linear-gradient(to top, #2a69bc 2px, transparent 2px) 0 100%,
        linear-gradient(to top, #2a69bc 2px, transparent 2px) 100% 100%`,
    width: 85,
    textAlign: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px 20px',
    cursor: 'pointer',
  },
};

function Sidebar() {
  const location = useLocation();
  const history = useHistory();
  const {
    dispatch,
    state: { user },
  } = useContext(UserContext);
  const [collapsed] = useState(false);

  const isAdmin = user.role === 'ROLE_SUPERADMIN';

  const logOut = () => {
    dispatch(resetLogin());
    history.push('/login');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };

  return (
    <div id="header">
      <ProSidebar collapsed={collapsed}>
        <SidebarHeader>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 32,
              visibility: collapsed ? 'hidden' : 'visible',
            }}
          >
            <CardMedia component="img" src={CopimaLogo} style={{ width: 259 }} />
          </div>
        </SidebarHeader>
        <SidebarContent style={{ paddingTop: 60 }}>
          <Menu iconShape="square">
            <MenuItem icon={<HomeIcon />} active={location.pathname === '/'}>
              <Typography class="title">Tableau de bord</Typography>
              <Link to="/" />
            </MenuItem>
            <MenuItem icon={<StorefrontIcon />} active={location.pathname === '/validation'}>
              <Typography class="title">Statistiques</Typography>
              <Link to="/" />
            </MenuItem>
            {isAdmin && (
              <SubMenu title={<Typography> Paramètres </Typography>} icon={<ViewListIcon />}>
                <MenuItem icon={<SettingsOutlinedIcon />} active={location.pathname === '/users'}>
                  <Typography class="title">Utilisateurs</Typography>
                  <Link to="/users" />
                </MenuItem>
              </SubMenu>
            )}
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape="square">
            {false && (
              <SubMenu
                title={<Typography> Paramètres </Typography>}
                icon={<SettingsOutlinedIcon />}
              >
                <MenuItem icon={<BusinessCenterIcon />} active={location.pathname === '/services'}>
                  <Typography>Services</Typography>
                  <Link to="/services" />
                </MenuItem>
                <MenuItem active={location.pathname === '/documentTypes'}>
                  <Typography>Référentiel des documents</Typography>
                  <Link to="/documentTypes" />
                </MenuItem>
                <MenuItem icon={<PostAddIcon />} active={location.pathname === '/types'}>
                  <Typography>Champs personnalisés</Typography>
                  <Link to="/types" />
                </MenuItem>
              </SubMenu>
            )}
            <MenuItem onClick={() => logOut()} icon={<LogoutIcon />}>
              <Typography class="title">Se déconnecter</Typography>
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
}

export default withStyles(styles)(Sidebar);
