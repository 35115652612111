/* eslint-disable import/prefer-default-export */
import { defaultMessage } from 'Shared/Referencial/ReferencialService';
import {
  aes256gcm, getJwkKeystore, getTransKey, JWE_KEY, KEYSTORE,
} from 'Utils/crypt';
import { login, loginSucces, loginError } from '../../Shared/UserContext/Actions';
import api from '../../config/apiConfig';

export const loginUser = async (dispatch, credential, history) => {
  try {
    const transKey = await getTransKey();
    const bodyFormData = new FormData();
    bodyFormData.set('username', credential.username);
    bodyFormData.set('password', credential.password);
    bodyFormData.set('transKey', transKey.encrypted);
    dispatch(login());

    const {
      data: { key, user, token },
    } = await api.post('/login', bodyFormData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    if (key) {
      const aesCipher = await aes256gcm(Buffer.from(transKey.clair, 'hex'));
      const keyJWE = await aesCipher.decrypt(
        Buffer.from(key.slice(0, 32), 'hex'),
        '0000000000000000',
        Buffer.from(key.slice(32, 64), 'hex'),
      );
      await localStorage.setItem(JWE_KEY, keyJWE);
      const keystore = await getJwkKeystore(keyJWE);
      await localStorage.setItem(KEYSTORE, keystore.toString());
    }
    dispatch(loginSucces(user, token));
    history.push('/');
  } catch (e) {
    const {
      response: {
        data: { statusLabel = defaultMessage },
      },
    } = e;
    dispatch(loginError(statusLabel));
  }
};
