import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ReferencialContext from 'Shared/ReferencialContext';
import { addUser, defaultMessage } from '../UsersService';
import { DEFAULT_VALUES, fields } from './constants';

const UserAdd = ({ handleClose, user, updateList }) => {
  const {
    state: { modules },
  } = useContext(ReferencialContext);
  const [values, setValues] = useState(
    (user && { ...user, modules: user.modules ? user.modules : [] }) || {
      ...DEFAULT_VALUES,
      password: user ? undefined : DEFAULT_VALUES.password,
    },
  );
  const [loading, setLoading] = useState(false);
  const handleChange = ({
    target: {
      name, value, type, checked,
    },
  }) => {
    const rest = name === 'modules'
      ? value.reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: Object.keys(values).includes(curr) ? values[curr] : false,
        }),
        {},
      )
      : {};
    setValues({ ...values, [name]: type === 'checkbox' ? checked : value, ...rest });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      await addUser({
        ...Object.keys(DEFAULT_VALUES).reduce(
          (prev, curr) => ({ ...prev, [curr]: values[curr] }),
          {},
        ),
        role: 'ROLE_USER',
        modules: modules
          .filter((m) => values.modules.includes(m.id))
          .map((m) => ({ module: m.id, admin: values[m.id] })),
        id: user ? user.id : undefined,
      });
      toast.success('Utilisateur ajouté avec succès');
      handleClose();
      updateList((prev) => !prev);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  };
  const isFilled = (user
    ? Object.keys(DEFAULT_VALUES).splice('password', 1)
    : Object.keys(DEFAULT_VALUES)
  ).reduce((prev, curr) => prev && values[curr], true);
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Dialog open onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Ajouter Un utilisateur</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={1}>
              {fields
                .filter((f) => (f.id !== 'password' ? true : !user))
                .map((field) => (
                  <Grid item xs={6} key={field.id}>
                    {field.type === 'text' && (
                      <TextField
                        label={field.label}
                        variant="outlined"
                        value={values[field.id]}
                        name={field.id}
                        onChange={handleChange}
                        fullWidth
                      />
                    )}
                    {field.type === 'password' && (
                      <TextField
                        label={field.label}
                        variant="outlined"
                        value={values[field.id]}
                        onChange={handleChange}
                        fullWidth
                        name={field.id}
                        type={values.showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Grid>
                ))}
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Modules</InputLabel>
                  <Select
                    value={values.modules}
                    name="modules"
                    onChange={handleChange}
                    label="Modules"
                    multiple
                  >
                    {modules.map((m) => (
                      <MenuItem value={m.id}>{m.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              {values.modules.map((m) => (
                <Grid item xs={6} key={m}>
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={values[m]}
                        onChange={handleChange}
                        name={m}
                        color="primary"
                      />
                    )}
                    label={`Utilisateur admin dans ${modules.find((md) => md.id === m)?.label}?`}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" variant="contained">
            Annuler
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary" disabled={!isFilled}>
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserAdd;
