import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const GlobalLoader = ({ size }) => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justify="center"
    style={{
      minHeight: size,
      backgroundColor: '#00000017',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 10000000,
    }}
  >
    <CircularProgress />
  </Grid>
);

GlobalLoader.propTypes = {};

export default GlobalLoader;
