import {
  secureDelete, secureGet, securePost, securePut,
} from 'config/apiConfig';

export const defaultMessage = 'Erreur système';
export const getUsers = async () => {
  const { data } = await secureGet('/users');
  return data;
};
export const deleteUser = async (id) => {
  const { data } = await secureDelete(`/users/delete/${id}`);
  return data;
};
export const addUser = async (user) => {
  const { data } = await securePut('/users/add', user);
  return data;
};
export const resteUserPassword = async (body) => {
  const { data } = await securePost('/users/password/reset', body);
  return data;
};
export const ChangeMyPassword = async (body) => {
  const { data } = await securePost('/users/password/change', body);
  return data;
};
