export const GET_MODULES = 'GET_MODULES';
export const getModules = () => ({
  type: GET_MODULES,
});

export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS';
export const getModulesSuccess = (data) => ({
  type: GET_MODULES_SUCCESS,
  data,
});
export const SET_MODULES = 'SET_MODULES';
export const setModules = (data) => ({
  type: SET_MODULES,
  data,
});

export const GET_MODULES_ERROR = 'GET_MODULES_ERROR';
export const getModulesError = (message) => ({
  type: GET_MODULES_ERROR,
  message,
});
