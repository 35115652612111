import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import ReferencialContext from 'Shared/ReferencialContext';

import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import { getModulesApi } from './ReferencialService';

const Referencial = ({ children }) => {
  const { dispatch } = useContext(ReferencialContext);
  const [loading, setLoading] = useState(false);

  const fetchModules = useCallback(async () => {
    await getModulesApi(dispatch, setLoading);
  }, [dispatch, setLoading]);
  useEffect(() => {
    fetchModules();
  }, [fetchModules]);
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      {children}
    </>
  );
};

export default Referencial;
