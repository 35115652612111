import React from 'react';
import { Box } from '@material-ui/core';
import Header from '../../Shared/Header';

import Modules from './Modules';

const HomeView = () => (
  <div style={{ height: '100vh' }}>
    {false && <Header />}
    <Box style={{ overflowY: 'auto', height: '100vh' }}>
      <div style={{
        height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}
      >
        <Modules />
      </div>
    </Box>
  </div>
);
HomeView.propTypes = {};

export default HomeView;
