import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 8,
    border: '1px solid rgba(42, 105, 188, 0.12)',
    margin: '20px 50px',
    padding: 0,
    background: '#FFFFFF',
  },
}));

export default useStyles;
