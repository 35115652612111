import React, { useContext, useMemo } from 'react';
import UserContext from 'Shared/UserContext';
import ReferencialContext from 'Shared/ReferencialContext';
import Module from '../Module';

const Modules = () => {
  const {
    state: { user },
  } = useContext(UserContext);
  const {
    state: { modules },
  } = useContext(ReferencialContext);
  const modulesList = useMemo(
    () => modules.filter((m) => user.role === 'ROLE_SUPERADMIN' || user.modules.includes(m.id)),
    [user, modules],
  );
  return (
    <div style={{ display: 'inline-flex', columnGap: 25 }}>
      {modulesList.map((module) => (
        <Module key={module.id} module={module} />
      ))}
    </div>
  );
};

export default Modules;
