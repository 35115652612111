import { getModules, getModulesSuccess, getModulesError } from 'Shared/ReferencialContext';
import { secureGet } from 'config/apiConfig';

export const defaultMessage = 'Erreur système';
export const getModulesApi = async (dispatch, setLoading) => {
  try {
    setLoading(true);
    dispatch(getModules());
    const { data } = await secureGet('/referential/modules');
    dispatch(getModulesSuccess(data));
  } catch (e) {
    const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
    dispatch(getModulesError(statusLabel));
  } finally {
    setLoading(false);
  }
};
