import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  color: '#727CA2',
  fontSize: '12px',
  fontWeight: 500,
  background: '#F8F8FF',
  borderBottom: 'none',
  textTransform: 'uppercase',
};
const defaultColumnStyle = { color: '#333D66', fontSize: '14px' };
export const columns = [
  {
    id: 'email',
    label: 'Email',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value}</Typography>
    ),
  },
  {
    id: 'firstName',
    label: 'FirstName',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value}</Typography>
    ),
  },
  {
    id: 'lastName',
    label: 'LastName',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'username',
    label: 'Username',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'action',
    label: 'Actions',
    style: TitleStyle,
    render: ({ value: { edit, deleteUser, password } }) => (
      <Grid container justify="flex-end" spacing={1}>
        <Grid item>
          <Button
            variant="contained"
            color="default"
            startIcon={<RotateLeftIcon />}
            onClick={password}
          >
            password
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={edit}>
            Edit
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteForeverIcon />}
            onClick={deleteUser}
          >
            Supprimer
          </Button>
        </Grid>
      </Grid>
    ),
  },
];
