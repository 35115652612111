import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from 'Shared/ProtectedRoute';
import ConnectedStore from 'Shared/ConnectedStore';
import { Provider, initialState, UserReducer } from 'Shared/UserContext';
import {
  Provider as ProviderRef,
  initialState as initialStateRef,
  ReferencialReducer,
} from 'Shared/ReferencialContext';
import history from 'config/history';
import Users, { ChangePassword } from 'Components/Users';
import Profile from 'Components/Profile';
import Login from './Components/Login';
import Home from './Components/Home';

function App() {
  return (
    <Router history={history}>
      <ToastContainer />
      <Switch>
        <Route path="/login" component={Login} exact />
        <ProtectedRoute path="/" component={Home} exact />
        <ProtectedRoute path="/users" component={Users} exact />
        <ProtectedRoute path="/password" component={ChangePassword} exact />
        <ProtectedRoute path="/profile" component={Profile} exact />
      </Switch>
    </Router>
  );
}

export default ConnectedStore(
  ConnectedStore(App, UserReducer, initialState, Provider),
  ReferencialReducer,
  initialStateRef,
  ProviderRef,
);
