/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Layout from 'Shared/Layout';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import Referencial from 'Shared/Referencial';
import { JWE_KEY } from 'Utils/crypt';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const archiscanIframeRef = useRef(null);
  const edgeIframeRef = useRef(null);
  const salesIframeRef = useRef(null);
  const [status, setStatus] = useState('loading');
  const token = localStorage.getItem('token');
  const handleLoad = (iframeRef, url) => () => {
    const receiver = iframeRef.current.contentWindow;
    let extraData = {};
    if (iframeRef === edgeIframeRef) {
      extraData = {
        key: localStorage.getItem(JWE_KEY),
        user: JSON.parse(localStorage.getItem('user')),
      };
    }
    receiver.postMessage({ token, ...extraData }, url);
    setStatus('');
  };
  const renderPage = (props) => {
    const { location } = props;
    const roleStore = localStorage.getItem('user');
    const role = roleStore == null ? null : JSON.parse(roleStore).role;
    const PERMITTED_ROLES = ['ROLE_SUPERADMIN', 'ROLE_USER'];
    if (!token || role == null || !PERMITTED_ROLES.includes(role)) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      );
    }
    return (
      <>
        <iframe
          ref={archiscanIframeRef}
          src="https://archiscan.copima.ma/?iframe=true"
          style={{ display: 'none' }}
          onLoad={handleLoad(archiscanIframeRef, 'https://archiscan.copima.ma/')}
        />
        <iframe
          ref={edgeIframeRef}
          src="https://edge.copima.ma/?iframe=true"
          style={{ display: 'none' }}
          onLoad={handleLoad(edgeIframeRef, 'https://edge.copima.ma/')}
        />
        <iframe
          ref={salesIframeRef}
          src="https://sales.copima.ma/?iframe=true"
          style={{ display: 'none' }}
          onLoad={handleLoad(salesIframeRef, 'https://sales.copima.ma/')}
        />
        {status === 'loading' && <GlobalLoader size="100vh" />}
        <Referencial>
          <Layout>
            <Component {...rest} {...props} />
          </Layout>
        </Referencial>
      </>
    );
  };
  return <Route {...rest} render={renderPage} />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default ProtectedRoute;
