import {
  Box, Button, Paper, Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import Header from 'Shared/Header';
import useStyles from './style';
import UserAdd from './UserAdd';
import UserList from './UserList';

const Users = () => {
  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [user, setUser] = useState();
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
    setUser(undefined);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const selectUser = (u) => () => {
    setUser(u);
    setOpen(true);
  };
  return (
    <Box>
      <Header />
      {open && <UserAdd handleClose={handleClose} user={user} updateList={setUpdate} />}
      <Box style={{ overflowY: 'auto', height: 'calc(100vh - 64px)' }}>
        <Box style={{ display: 'flex', margin: '20px 50px' }}>
          <Typography variant="h5" style={{ flexGrow: 1 }}>
            Gestion des utilisateus
          </Typography>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            Ajouter un utilisateur
          </Button>
        </Box>

        <Paper elevation={0} classes={{ root: classes.paper }}>
          <UserList selectUser={selectUser} update={update} />
        </Paper>
      </Box>
    </Box>
  );
};

export default Users;
