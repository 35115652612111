import {
  GET_MODULES, GET_MODULES_SUCCESS, GET_MODULES_ERROR, SET_MODULES,
} from './Actions';

const ReferencialReducer = (state, action) => {
  switch (action.type) {
    case SET_MODULES:
      return { ...state, modules: action.data };
    case GET_MODULES:
      return { ...state, status: 'loading' };
    case GET_MODULES_SUCCESS:
      return { ...state, status: 'success', modules: action.data };
    case GET_MODULES_ERROR:
      return { ...state, status: 'error', errorMessage: action.message };
    default:
      throw new Error();
  }
};

export default ReferencialReducer;
