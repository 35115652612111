import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import { defaultMessage } from 'Shared/Referencial/ReferencialService';
import { resteUserPassword } from '../UsersService';

const Password = ({ handleClose, user }) => {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const handleChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleUpdatePassword = async () => {
    try {
      setLoading(true);
      await resteUserPassword({
        ...values,
        userId: user.id,
      });
      toast.success('Utilisateur ajouté avec succès');
      handleClose();
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Dialog open>
        <DialogTitle id="alert-dialog-title">
          {`Changer Le mot de passe de l'utilisateur ${user.username}`}
        </DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Password</InputLabel>
            <OutlinedInput
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              name="newPassword"
              onChange={handleChange}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
              labelWidth={70}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" variant="contained">
            Annuler
          </Button>
          <Button onClick={handleUpdatePassword} color="primary" variant="contained" disabled={!values.newPassword}>
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Password;
