import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ConfirmDialog from 'Shared/ConfirmDialog';
import EnhancedTable from 'Shared/EnhancedTable';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import Password from '../Password/Password';
import { defaultMessage, deleteUser, getUsers } from '../UsersService';
import { columns } from './constants';

const UserList = ({ selectUser, update }) => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePassword = () => {
    setOpenPassword(false);
  };
  const firePopup = (u, setOpenFun) => () => {
    setUser(u);
    setOpenFun(true);
  };
  const getUserList = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getUsers();
      setUsers(
        data.map((u) => ({
          ...u,
          action: {
            edit: selectUser(u),
            deleteUser: firePopup(u, setOpen),
            password: firePopup(u, setOpenPassword),
          },
        })),
      );
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setUsers, setLoading, setOpen, setOpenPassword]);
  useEffect(() => {
    getUserList();
  }, [getUserList, update]);
  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteUser(user.id);
      setOpen(false);
      getUserList();
      toast.success('Utilisateur a été supprimé avec succès');
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      {open && (
        <ConfirmDialog
          title="Suppression"
          content={`Voulez vous vraiment supprimer l'utilisateur ${user.username} ?`}
          handleClose={handleClose}
          handleClick={handleDelete}
        />
      )}
      {openPassword && <Password handleClose={handleClosePassword} user={user} />}
      <EnhancedTable
        getData={getUserList}
        rows={users}
        headCells={columns}
        count={users.length}
        rowsPerPageOptions={[10, 15, 20]}
      />
    </>
  );
};

export default UserList;
