import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paper: {
    width: 456,
    height: 420,
    borderRadius: 20,
    padding: 32,
  },
  borderCorner: {
    background:
            `linear-gradient(to right, #2a69bc 4px, transparent 4px) 0 0,
        linear-gradient(to right, #2a69bc 4px, transparent 4px) 0 100%,
        linear-gradient(to left, #2a69bc 4px, transparent 4px) 100% 0,
        linear-gradient(to left, #2a69bc 4px, transparent 4px) 100% 100%,
        linear-gradient(to bottom, #2a69bc 4px, transparent 4px) 0 0,
        linear-gradient(to bottom, #2a69bc 4px, transparent 4px) 100% 0,
        linear-gradient(to top, #2a69bc 4px, transparent 4px) 0 100%,
        linear-gradient(to top, #2a69bc 4px, transparent 4px) 100% 100%`,
    width: 180,
    textAlign: 'center',
    margin: 'auto',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px 20px',
  },
  labelField: {
    font: 'normal normal 600 16px/19px Montserrat',
    letterSpacing: 0,
    color: '#AFB0B2',
    opacity: 1,
  },
  notchedField: {
    border: '2px solid #C5C5C5',
    borderRadius: 12,
  },
}));

export default useStyles;
