/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useHistory } from 'react-router-dom';
import { Menu, MenuItem } from '@material-ui/core';
import UserContext from 'Shared/UserContext';
import { resetLogin } from 'Shared/UserContext/Actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: 14,
    lineHeight: 14,
  },
  media: {
    width: 103,
    height: 60,
  },
  cube: {
    width: 24,
    height: 22,
  },
  profile: {
    fontSize: 12,
    lineHeight: '138 %',
    letterSpacing: '0.0075em',
    color: '#B3E5FB',
  },
}));
const mapRole = (role) => {
  if (role === 'ROLE_DISPATCHER') {
    return 'dispatcher';
  }
  if (role === 'ROLE_ADMIN') {
    return 'admin';
  }
  if (role === 'ROLE_LIAISON') return 'liaison';
  return role;
};
const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const [catalog, setCatalog] = useState([]);
  const [search, setSearch] = useState();
  const {
    dispatch,
    state: { user },
  } = useContext(UserContext);
  const logout = () => {
    dispatch(resetLogin());
    history.push('/login');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRouteChnagePassword = () => {
    history.push('/password');
    handleClose();
  };
  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Grid container>
            <Grid item xs={2} />
            <Grid item xs={6} />
            <Grid item xs={4} container>
              <div style={{ width: '100%', height: 62 }}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justify="center"
                  alignContent="flex-end"
                  style={{ height: '100%' }}
                >
                  <Grid item style={{ margin: '0 15px', color: '#2188cc' }}>
                    <IconButton
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleRouteChnagePassword}>Changer mon mot de passe</MenuItem>
                    </Menu>
                  </Grid>
                  <Grid item style={{ margin: '0 15px', color: '#2188cc' }}>
                    <Typography variant="body1">{user.username}</Typography>
                    <Typography variant="body2" className={classes.profile}>
                      {`${mapRole(user.role)}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      color="inherit"
                      onClick={logout}
                      style={{ padding: 0, color: '#2188cc' }}
                    >
                      <ExitToAppIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

Header.propTypes = {};

export default Header;
