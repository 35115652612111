import { Box, TextField, Typography } from '@material-ui/core';
import React from 'react';
import Header from 'Shared/Header';

const containerStyleInput = { width: 300, marginBottom: 10 };
const ChangePassword = () => (
  <Box>
    <Header />
    <div style={{ margin: '10px 50px 10px 50px' }}>
      <Typography>Changer votre mot de passe</Typography>
      <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Box style={containerStyleInput}>
          <TextField id="old-pass" label="Ancien mot de passe" variant="outlined" fullWidth />
        </Box>
        <Box style={containerStyleInput}>
          <TextField id="new-pass" label="Nouveau mot de passe" variant="outlined" fullWidth />
        </Box>
        <Box style={containerStyleInput}>
          <TextField
            id="new-pass-confirm"
            label="Confirmer le nouveau mot de passe"
            variant="outlined"
            fullWidth
          />
        </Box>
      </Box>
    </div>
  </Box>
);

export default ChangePassword;
